import dashboard from "./dashboard.json"
import brand from "./brand-management.json"
import clientManagement from "./client-management.json"
import billingManagement from "./billing-management"
import userManagement from "./user-management.json"
import domainManagement from "./domain-management.json"
import approval from "./approval.json"
import notification from "./notification-management.json"
import ticket from "./support-ticket.json"
import setting from "./setting.json"
import error from "./error.json"
import menu from "./menu.json"
import profile from "./user-profile.json"
import message from "./message.json"
import dropdown from "./dropdown.json"
import authentication from "./authentication.json"
import payment from "./payment.json"
import activityLog from "./activity-log.json"
import require from "./require.json"
import status from "./status.json"

const translate = {
  ID: "ID",
  Email: "電子メール",
  Password: "パスワード",
  "Email is required": "メールアドレスは必須です",
  "Password is required": "パスワードが必要です",
  "Client Name": "クライアント名",
  Packages: "Packages",
  "Created at": "広告を作成する",
  "View details": "詳細を見る",
  "View Details": "詳細を見る",
  "Forgot password Forgot": "パスワードをお忘れですか",
  "Remember login": "ログインを記憶する",
  "Log in your account": "アカウントにログインします",
  "Powered by": "パワード・バイ",
  "Edit image": "画像を編集",
  OK: "了解",
  Change: "変化",
  "Choose an option": "オプションを選択",
  "File size exceeds the allowed limit 2 MB": "ファイルサイズが許可された2MBを超えています",
  "This file doesn't support.": "このファイルはサポートされていません",
  "Notification Detail": "通知の詳細",
  "attachment files": "添付ファイル",
  s: "秒",
  m: "分",
  h: "時",
  d: "日",
  ago: "前",
  Done: "完了",
  "You have reached your limit in billing?": "請求限度額に達しましたか?",
  "Let Us Solve Your Problem!": "あなたの問題を解決しましょう!",
  "Contact us": "お問い合わせ",
  "This page is comming soon": "このページは近日公開予定です",
  "This page is not unknown or does not exsit": "このページは不明ではないか、存在しません",
  "Sorry about that, but the page you looking for is not available":
    "申し訳ありませんが、お探しのページは見つかりませんでした",
  "Service unavailable": "サービスが利用できません",
  "This server is temporary busy, try again later":
    "このサーバーは一時的に混雑しています。後でもう一度お試しください",
  "System Maintenance Scheduled": "システムメンテナンスの予定",
  "We'll be performing system maintenance from": "システムメンテナンスを次の時間帯に実施します",
  to: "から",
  Time: "時刻",
  "to improve system performance and reliability. During this time, you may experience temporary disruptions to the service. We apologize for any inconvenience this may cause.":
    "システムのパフォーマンスと信頼性を向上させるためです。この間、サービスに一時的な中断が発生する可能性があります。ご不便をおかけしますことをお詫び申し上げます。",
  Picture: "写真",
  "Picture here": "画像はこちら",
  "List of user accounts managed by admin": "管理者が管理するユーザーアカウントのリスト",
  "after the street number": "番地の後",
  "to town": "街へ",
  "Re-enter your password": "パスワードを再入力してください",
  "e.g. Tokyo-to Shibuya-ku Sakuragaoka-cho": "例：東京都渋谷区桜丘町",
  "e.g. 1-2-3 ABC Building, 4th Floor": "例：1-2-3 ABCビル4階",
  ...dashboard,
  ...brand,
  ...clientManagement,
  ...billingManagement,
  ...userManagement,
  ...domainManagement,
  ...approval,
  ...notification,
  ...ticket,
  ...setting,
  ...error,
  ...menu,
  ...profile,
  ...message,
  ...dropdown,
  ...authentication,
  ...payment,
  ...activityLog,
  ...require,
  ...status
}

export default translate
